import Swiper from 'swiper';
import 'swiper/swiper-bundle.css'
import './index.scss'

let swiper = new Swiper(".services", {
    breakpoints: {
        1200: {
            slidesPerView: 4,
        },
        992: {
            slidesPerView: 3,
        },
        600: {
            slidesPerView: 2,
        },
        300: {
            slidesPerView: 1,
        },
    },
});