import './index.scss'
import './remove_from_cart'

document.querySelectorAll('.header_button__basket').forEach(element => {
    element.addEventListener('click', function () {
        document.querySelector('.basket_modal_wrapper').classList.add('active');
    });
});

document.body.addEventListener('click', (e) => {
    const target = e.target
    if (!target.closest('.basket_modal_wrapper') && !target.closest('.header_button__basket')) {
        document.querySelector('.basket_modal_wrapper').classList.remove('active')
    }
})

document.querySelector('.basket_modal_close').addEventListener('click', function () {
    document.querySelector('.basket_modal_wrapper').classList.remove('active');
})



if (document.querySelector('.basket_product')) {

    document.querySelectorAll('.basket_product__remove > button').forEach(btn => {
        btn.addEventListener('click', () => {
            let id = btn.dataset.id
            fetch(`/api/cart_item/${id}/`, {
                method: 'DELETE',
            })
                .then(response => {
                    response.json()
                    if (response.ok) {
                        remove(id)
                    }
                })
        })
    })

    if (document.querySelector('.remove-all > button')) {
        document.querySelector('.remove-all > button').addEventListener('click', () => {
            fetch(`${document.querySelector('.remove-all > button').dataset.href}`, {
                method: 'DELETE',
            })
                .then(response => {
                    response.json()
                    if (response.ok) {
                        document.querySelectorAll('.basket_product').forEach(item => item.remove())
                        if (document.querySelector('.header_basket_count')) {
                            document.querySelector('.header_basket_count').textContent = 0
                        }
                        if (document.querySelector('.header_basket_count-mob')) {
                            document.querySelector('.header_basket_count-mob').textContent = 0
                        }
                        sumAll()
                    }
                })
        })
    }
    
    document.querySelectorAll('.product_quantity__increment').forEach(item => {
        item.addEventListener('click', (e) => {
            let count = {
                quantity: +e.target.previousSibling.previousSibling.textContent + 1
            } 
            let id = e.target.dataset.id
    
            fetch(`/api/cart_item/${id}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Accept": "application/json"
                },
                body: JSON.stringify(count)
            })
                .then(response => {
                    response.json()
                    if (response.ok) {
                        sumAll()
                    }
                })
        })
    })

    document.querySelectorAll('.product_quantity__decrement').forEach(item => {
        item.addEventListener('click', (e) => {
            let count = {
                quantity: +e.target.nextSibling.nextSibling.textContent - 1
            } 
            let id = e.target.dataset.id
            if (count.quantity > 0) {
                fetch(`/api/cart_item/${id}/`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        "Accept": "application/json"
                    },
                    body: JSON.stringify(count)
                })
                    .then(response => {
                        response.json()
                        if (response.ok) {
                            sumAll()
                        }
                    })
            }
        })
    })

    function remove(id) {
        if (document.querySelector('.header_basket_count')) {
            document.querySelector('.header_basket_count').textContent = +document.querySelector('.header_basket_count').textContent - 1
        }
        if (document.querySelector('.header_basket_count-mob')) {
            document.querySelector('.header_basket_count-mob').textContent = +document.querySelector('.header_basket_count-mob').textContent - 1
        }
        
        document.querySelectorAll('.basket_product__remove > button').forEach(btn => {
            if (btn.dataset.id === id) {
                btn.closest('.basket_product').remove()
            }
        })
        sumAll()
    }
    
    function sumAll() {
        let result_sum = 0
    
            if (document.querySelector('.basket_section')) {
                document.querySelectorAll('.basket_section .product_price__value-sum').forEach(sum => result_sum += +sum.textContent)
                document.querySelector('.basket_section .total_sum__value-number').textContent = result_sum
    
                document.querySelector('.basket_section .total_sum__title').textContent = document.querySelectorAll('.basket_section .basket_product').length + ' товара на сумму'
            }
    
            if (document.querySelector('.basket_modal_wrapper')) {
                result_sum = 0
                document.querySelectorAll('.basket_modal_wrapper .product_price__value-sum').forEach(sum => result_sum += +sum.textContent)
                document.querySelector('.basket_modal_wrapper .total_sum__value-number').textContent = result_sum
    
                document.querySelector('.basket_modal_wrapper .total_sum__title').textContent = document.querySelectorAll('.basket_modal_wrapper .basket_product').length + ' товара на сумму'
            }
    }
    
    sumAll()
}