import './search_modal'

document.querySelector(".navigation_search_input").addEventListener("keydown", function (e) {
    if (e.target.value !== "" && e.keyCode === 13) {
        e.preventDefault();
        let querySearch = document.querySelector(".navigation_search_input").value;
        sessionStorage.setItem("search__site", querySearch);
        let search_content = sessionStorage.getItem("search__site");
        location.href = `/search/?${search_content}`;
    }
});