import './search/index.scss';
import "./search/index";
import './basket_modal/index';
import './index.scss';
import './product_quantity/index'

window.onload = function() {

    let navigation_phone_arrow = document.querySelectorAll('.navigation_phone_arrow');
    navigation_phone_arrow.forEach(phone_element => {
        phone_element.addEventListener("click", function () {
            let navigation_another_phone = phone_element.closest('.header_phone__block').querySelector('.header_another_phone');
            navigation_another_phone.classList.toggle('active');
        });
    });
    
    let menu_btn = document.querySelector('.header_hamburger');
    menu_btn.addEventListener("click", menu_action);

    function menu_action() {
        let header_navigation = document.querySelector('.header');
        header_navigation.classList.toggle('active');
    }
}

document.querySelector('.catalog__btn').addEventListener('click', () => {
    document.querySelector('.dot_container').classList.toggle('active')
    document.querySelector('.nav_items').classList.toggle('mobile')
    document.querySelector('.nav_hor_mobile').classList.remove('active')
    document.querySelector('.header').classList.remove('active')
    if (document.querySelector('.filter')) {
        document.querySelector('.filter').classList.remove('filter_mob')
        document.querySelector('.filter__btn-show').classList.remove('active')
    }
    if (document.querySelector('.nav_items').classList.contains('mobile')) {
        document.querySelector('.overlay').classList.add('active')
    }
    else {
        document.querySelector('.overlay').classList.remove('active')
    }
})

hamburger.addEventListener('click', () => {
    document.querySelector('.dot_container').classList.remove('active')
    document.querySelector('.nav_hor_mobile').classList.toggle('active')
    document.querySelector('.nav_items').classList.remove('mobile')
    if (document.querySelector('.filter')) {
        document.querySelector('.filter').classList.remove('filter_mob')
        document.querySelector('.filter__btn-show').classList.remove('active')
    }
    if (document.querySelector('.nav_hor_mobile').classList.contains('active')) {
        document.querySelector('.overlay').classList.add('active')
    }
    else {
        document.querySelector('.overlay').classList.remove('active')
    }
})


if (document.documentElement.clientWidth <= 992) {
    document.querySelector('.header_mob_bottom').append(document.querySelector('.header_search__block')) 
}
else {
    document.querySelector('.header_main__logo').after(document.querySelector('.header_search__block'))
}

window.addEventListener('resize', () => {
    //document.querySelector('.header_mob_bottom').append(document.querySelector('.header_search__block')) 
    if (document.documentElement.clientWidth > 992) {
        //document.querySelector('.header_main__logo').after(document.querySelector('.header_search__block'))
        document.querySelector('.nav_hor_mobile').classList.remove('active')
        document.querySelector('.nav_items').classList.remove('mobile')
        document.querySelector('.header').classList.remove('active')
        document.querySelector('.dot_container').classList.remove('active')
        if (document.querySelector('.filter')) {
            document.querySelector('.filter').classList.remove('filter_mob')
        }
    }
})

document.querySelectorAll('[data-href="quckly_shop"]').forEach(btn => {
    btn.addEventListener('click', (e) => {
        console.log(e.target.dataset.product_id);
        document.querySelector('#quckly_id').value = e.target.dataset.product_id
    })
})

document.querySelector('.header_top__close').addEventListener('click', () => {
    document.querySelector('.header_top').remove()
})