import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'
import './index.scss'

Swiper.use([Navigation])

let swiper = new Swiper(".nav_hor", {
    slidesPerView: 'auto',
    slidesPerGroup: 2,
    spaceBetween: 15,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});


if (document.querySelector('.language_select')) {
    let select = document.querySelector('.language_select').options

    select.selectedIndex = document.querySelector('.language_select > .active').index
    
    document.querySelector('.language_select').addEventListener('change', () => {
        window.location = select[select.selectedIndex].dataset.href
        select.selectedIndex = select.selectedIndex
    })
}

document.querySelectorAll('.nav__item-main').forEach(link => {
    link.addEventListener('click', () => {
        if (document.querySelector('.nav_items').classList.contains('mobile')) {
            link.classList.add('active')
            //e.preventDefault()
        }
    })
})

if (document.documentElement.clientWidth < 992) {
    document.querySelectorAll('.img_green').forEach(img => img.classList.add('active'))
    document.querySelectorAll('.img_white').forEach(img => img.classList.remove('active'))
}
else {
    document.querySelectorAll('.img_green').forEach(img => img.classList.remove('active'))
    document.querySelectorAll('.img_white').forEach(img => img.classList.add('active'))
}

window.addEventListener("resize", () => {
    if (document.documentElement.clientWidth < 992) {
        document.querySelectorAll('.img_green').forEach(img => img.classList.add('active'))
        document.querySelectorAll('.img_white').forEach(img => img.classList.remove('active'))
    }
    else {
        document.querySelectorAll('.img_green').forEach(img => img.classList.remove('active'))
        document.querySelectorAll('.img_white').forEach(img => img.classList.add('active'))
    }
})



document.querySelectorAll('.nav__item-main').forEach(item => {
    item.addEventListener('mouseover', () => {
        if (!document.querySelector('.nav_items').classList.contains('mobile')) {
            document.querySelectorAll('.nav__item_wrapper').forEach(item => item.classList.remove('active'))
            document.querySelectorAll('.nav__item-second').forEach(item => item.classList.remove('active'))
            document.querySelector('.nav__item_content_container_2').classList.remove('active')
            item.parentNode.classList.add('active')

        }
    })
})

document.querySelectorAll('.nav__item-second').forEach(item => {
    item.addEventListener('mouseover', () => {
        if (!document.querySelector('.nav_items').classList.contains('mobile')) {
            document.querySelectorAll('.nav__item-second').forEach(item => item.classList.remove('active'))
            item.classList.add('active')
        }
    })
})


if (document.documentElement.clientWidth > 992) {
    let count = 0
    document.querySelectorAll('.nav__item_wrapper').forEach(el => {
        count += el.offsetHeight
        if (count > 415) {
            el.classList.add('hidden')
        }
    })
    
    document.querySelector('.nav').addEventListener('mouseenter', () => {
        document.querySelectorAll('.nav__item_wrapper').forEach(el => {
            el.classList.remove('hidden')
        })
    })
    
    document.querySelector('.nav').addEventListener('mouseleave', () => {
        document.querySelectorAll('.nav__item_wrapper').forEach(item => item.classList.remove('active'))

        let count = 0
        document.querySelectorAll('.nav__item_wrapper').forEach(el => {
            count += el.offsetHeight
            if (count > 415) {
                el.classList.add('hidden')
            }
        })
        document.querySelectorAll('.nav__item_content').forEach(el => {
            el.classList.remove('active')
        })
        document.querySelectorAll('.nav__item_content_container').forEach(container => container.classList.remove('active'))
    })
    
    document.querySelectorAll('.nav__item_content').forEach(el => {
        document.querySelector(`.nav__item_content_container_${el.dataset.append}`).append(el)
    })
    
    document.querySelectorAll('.nav__item-main').forEach(el => {
        el.addEventListener('mouseenter', (e) => {
            document.querySelectorAll('.nav__item_content').forEach(el => {
                el.classList.remove('active')
            })
            document.querySelector('.nav__item_content_container').classList.remove('active')
    
            if (document.querySelector(`.nav__item_content_container > [data-id="${e.target.dataset.id}"]`)) {
                document.querySelector(`.nav__item_content_container > [data-id="${e.target.dataset.id}"]`).classList.add('active')
                document.querySelector('.nav__item_content_container').classList.add('active')
            }
        })
    })

    document.querySelectorAll('.nav__item-second').forEach(el => {
        el.addEventListener('mouseenter', (e) => {
            document.querySelectorAll('.nav__item_content_container_2 .nav__item_content').forEach(el => {
                el.classList.remove('active')
            })
            document.querySelector('.nav__item_content_container_2').classList.remove('active')
    
            if (document.querySelector(`.nav__item_content_container_2 > [data-id="${e.target.dataset.id}"]`)) {
                document.querySelector(`.nav__item_content_container_2 > [data-id="${e.target.dataset.id}"]`).classList.add('active')
                document.querySelector('.nav__item_content_container_2').classList.add('active')
            }
        })
    })
}