import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Pagination, Navigation])

import './index.scss'

let swiper = new Swiper(".banner", {
    loop: true,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

let reviews_swipper = new Swiper(".reviews_block", {
    lazy: true,
    spaceBetween: 10,
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
});

let brands_swipper = new Swiper(".brands", {
    slidesPerView: 5,
    slidesPerGroup: 1,
    spaceBetween: 20,
    breakpoints: {
        992: {
            slidesPerView: 5,
        },
        700: {
            slidesPerView: 4,
        },
        500: {
            slidesPerView: 3,
        },
        300: {
            slidesPerView: 2,
        },
    },
});