import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css'
import './index.scss'
import './select_item'

Swiper.use([Autoplay])

let swiper = new Swiper(".swiper", {
    lazy: true,
    //slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    breakpoints: {
        1600: {
            slidesPerView: 5,
        },
        1200: {
            slidesPerView: 4,
        },
        900: {
            slidesPerView: 3,
        },
        600: {
            slidesPerView: 2,
        },
    },
});
