// script interface
import '../components/common_componentc/normalize/index'
import '../components/interface/grid/index'
import '../components/interface/form/index'
import '../components/interface/button/index'


// script common elements
import '../components/module/catalog_elements/index'
// import '../components/module/subscribe_section/index'
import '../components/module/modal_script/index'
import '../components/interface/category_section/index'
import '../../source/lib/custom_mask/index'


// script pages
import '../components/common_componentc/header/index'
import '../components/common_componentc/top/index'
import '../components/common_componentc/services/index'
import '../components/common_componentc/goods/index'
import '../components/common_componentc/footer/index'
import '../components/common_componentc/newsletter/index'
// import '../components/module/validation/index'
import '../components/common_componentc/form_action/index'

import '../components/pages/index/index'