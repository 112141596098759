let remove_btns = document.querySelectorAll('.basket_product__remove')

remove_btns.forEach(btn => {
    btn.addEventListener('click', () => {
        let basket_product = btn.closest('.basket_product')

        let name = basket_product.querySelector('.basket_product [data-name]').dataset.name
        let price = basket_product.querySelector('.basket_product [data-price]').dataset.price
        let brand = basket_product.querySelector('.basket_product [data-brand]').dataset.brand
        let category = basket_product.querySelector('.basket_product [data-category]').dataset.category
        let index = basket_product.querySelector('.basket_product [data-id]').dataset.id
        let quantity = basket_product.querySelector('.basket_product [data-quantity]').dataset.quantity

        window.dataLayer = window.dataLayer || [];

        dataLayer.push({
            'event': 'remove_from_cart',
            'ecommerce': {
                'items': [{
                    'item_name': name,
                    'price': price,
                    'item_brand': brand,
                    'item_category': category,
                    'item_list_name': '',
                    'item_list_id': '',
                    'index': index,
                    'quantity': quantity
                }]
            }
        });

    })
})