let goods_carts = document.querySelectorAll('.goods__cart')

goods_carts.forEach(cart => {
    cart.addEventListener('click', () => {

        let name = cart.querySelector('[data-name]').dataset.name
        let price = cart.querySelector('[data-price]').dataset.price
        let brand = cart.querySelector('[data-brand]').dataset.brand
        let category = cart.querySelector('[data-category]').dataset.category
        let index = cart.querySelector('[data-product_id]').dataset.product_id

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'select_item',
            'ecommerce': {
                'items': [{
                    'item_name': name,
                    'price': price,
                    'item_brand': brand,
                    'item_category': category,
                    'index': index,
                    'quantity': 1
                }]
            }
        });
    })
})