import './index.scss'

document.querySelectorAll('.product_quantity__decrement').forEach(item => {
    item.addEventListener('click', (e) => {
        if (e.target.nextElementSibling.textContent > 1) {
            let id = e.target.dataset.id
            let count = +e.target.nextElementSibling.textContent - 1
            
            document.querySelectorAll('.product_quantity__value').forEach(item => {
                if (item.dataset.id === id) {
                    item.textContent = count
                }
            })
            document.querySelectorAll('.product_price__value-sum').forEach(item => {
                if (item.dataset.id === id) {
                    item.textContent = +e.target.closest('.basket_product').querySelector('.product_price__value-one').textContent * count;
                }
            })
        }
    })
})

document.querySelectorAll('.product_quantity__increment').forEach(item => {
    item.addEventListener('click', (e) => {
        let id = e.target.dataset.id
        let count = +e.target.previousElementSibling.textContent + 1

        document.querySelectorAll('.product_quantity__value').forEach(item => {
            if (item.dataset.id === id) {
                item.textContent = count
            }
        })
        document.querySelectorAll('.product_price__value-sum').forEach(item => {
            if (item.dataset.id === id) {
                item.textContent = +e.target.closest('.basket_product').querySelector('.product_price__value-one').textContent * count;
            }
        })
    })
})